import React, { useState, useEffect } from 'react'
import Layout from '../layout'
import Card from '../common/Card'
import Button from '../common/Button'
import { Helmet } from 'react-helmet'

export const NewsSection = ({ blogs, blogType, title }) => {
	const [limit, setLimit] = useState(3)

	const filteredArticles = blogs?.filter((blog) => blog.BlogType == blogType)

	const handleSeeMore = () => {
		console.log('see more!!')
		setLimit(limit + 3)
	}

	return (
		<div className="section-spacing auto-layout auto-layout-40">
			<h3>{title || blogType}</h3>
			<div className="blog-type-container gc">
				{filteredArticles?.map(
					(blog, i) =>
						i < limit && (
							<>
								<div
									className={`blog-card span-4 t-span-6 m-span-12`}
									key={`blog-thumbnail-${i}`}
								>
									<Card data={blog} />
								</div>
							</>
						)
				)}
			</div>
			{filteredArticles?.length > limit && (
				<div className="w-full flex">
					<button class="primary" onClick={handleSeeMore}>
						See More
					</button>
				</div>
			)}
		</div>
	)
}

const BlogPageTemplate = ({ allStrapiBlogs }) => {
	return (
		<Layout>
			<Helmet>
				<title>Delta Insurance | News</title>
				<meta
					name="description"
					content="The latest news and updates from Delta Insurance"
				/>
			</Helmet>
			<div className="page-container">
				<section data-section-id="news-index">
					<h1 class="h2 spacing-large m-spacing-small">News</h1>
					<NewsSection
						blogs={allStrapiBlogs?.nodes}
						blogType="About"
						title="About Delta"
					/>
					<NewsSection blogs={allStrapiBlogs?.nodes} blogType="Industry" />
				</section>
			</div>
		</Layout>
	)
}

export default BlogPageTemplate

import React from "react";
import { StaticQuery, graphql } from "gatsby"
import CTACard from "../CTACard";


const AllProducts = ({ data }) => {
    console.log(data);
    return (
        <StaticQuery
            query={graphql`
                query AllProductsQuery {
                    allStrapiProducts {
                        nodes {
                            Slug
                            Title
                            ProductType
                            Paragraph
                            HeroImage {
                                provider_metadata {
                                    public_id
                                }
                                formats {
                                    thumbnail {
                                        url
                                    }
                                    small {
                                        url
                                    }
                                    medium {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <>
                    <ProductGroup products={data.allStrapiProducts.nodes} productType="Financial" title="Financial Lines" />
                    <ProductGroup products={data.allStrapiProducts.nodes} productType="Property" />
                    <ProductGroup products={data.allStrapiProducts.nodes} productType="Technology" />
                    <ProductGroup products={data.allStrapiProducts.nodes} productType="Environmental" />
                    <ProductGroup products={data.allStrapiProducts.nodes} productType="Speciality" />
                    <ProductGroup products={data.allStrapiProducts.nodes} productType="Casualty" />
                </>
            )}
        />
    )
}

const ProductGroup = ({ products, productType, title }) => {
    return (
        <section data-section-id="all-products" id={productType}>
            <h3 className="spacing-bottom-40">{title ? title : productType}</h3>
            <div className="gc-3-col grid-gap-1-col m-gc-1-col m-row-gap-80">
                {products.map((product, i) => (
                    product.ProductType === productType ?
                    <CTACard 
                        key={i}
                        context="featured-page-card"
                        image={product.HeroImage}
                        title={product.Title || ''}
                        summary={product.Paragraph.substring(0, 150) || ''}
                        buttonText="Learn more"
                        buttonUrl={`/products/${product.Slug}`}
                    />
                    : null
                ))}
            </div>
        </section>
    )
}

export default AllProducts;
  import React from 'react'

const CasualtyIcon = () => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000"
 preserveAspectRatio="xMidYMid meet" style={{width:'40px', height: 'auto'}}>

<g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
fill="#474848" stroke="none">
<path d="M887 1986 c-85 -31 -109 -76 -115 -218 -9 -221 65 -414 220 -573 50
-53 57 -63 46 -78 -12 -16 -29 -15 -265 15 l-251 31 -154 -75 c-181 -88 -211
-111 -228 -173 -23 -83 16 -160 95 -190 59 -23 89 -16 227 51 l113 56 82 -7
c46 -4 85 -11 88 -15 3 -5 -44 -63 -104 -130 -116 -127 -130 -153 -118 -226 7
-44 40 -90 79 -110 39 -20 105 -21 143 -3 24 11 336 343 535 568 l47 54 60
-21 c110 -38 228 -55 343 -49 58 4 123 11 145 18 111 31 159 166 88 250 -47
55 -71 61 -209 53 -181 -10 -282 16 -419 108 -179 120 -246 244 -244 448 1
115 -7 145 -53 188 -22 20 -77 43 -103 42 -5 -1 -27 -7 -48 -14z m109 -73 c30
-19 32 -30 37 -178 4 -138 21 -195 90 -298 68 -101 212 -204 356 -255 71 -25
87 -27 244 -27 166 0 169 0 188 -24 30 -36 33 -66 12 -108 -26 -54 -67 -66
-228 -66 -136 -1 -221 14 -322 54 -29 12 -56 19 -61 17 -5 -1 -130 -136 -277
-298 -147 -162 -279 -305 -293 -317 -62 -53 -162 -8 -162 71 0 39 1 41 172
227 101 110 118 144 76 153 -13 3 -77 12 -142 20 l-119 14 -132 -65 c-146 -73
-164 -75 -216 -23 -35 35 -38 76 -8 114 13 16 83 58 171 102 l150 75 256 -32
255 -32 44 46 c51 54 49 65 -16 116 -94 74 -198 255 -227 391 -22 106 -16 259
12 297 40 54 85 62 140 26z"/>
<path d="M1434 1887 c-143 -54 -213 -217 -150 -348 91 -192 361 -195 463 -7
25 46 25 180 0 226 -60 111 -204 170 -313 129z m154 -62 c162 -68 162 -292 0
-360 -96 -40 -205 4 -249 100 -73 158 89 326 249 260z"/>
<path d="M6 44 c-17 -45 -40 -44 994 -44 1034 0 1011 -1 994 44 -6 15 -91 16
-994 16 -903 0 -988 -1 -994 -16z"/>
</g>
</svg>

)

export default CasualtyIcon